import { fetchAsObservable, getCalls } from "fetcher!sofe";
import canopyUrls from "canopy-urls!sofe";
import { isEmpty } from "lodash";
import { throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { asyncStacktrace } from "auto-trace";

export default function fetcher(...args) {
  let [url, options] = args;
  options = options || {};

  if (
    options.method &&
    url.indexOf("workspaces") === -1 &&
    ["POST", "PUT", "PATCH", "DELETE"].includes(options.method)
  ) {
    return proceedToFetch(url, options);
  } else {
    return proceedToFetch(url, options);
  }
}

window.fetcher = fetcher;

function proceedToFetch(passedUrl, options) {
  let url = resolveUrl(passedUrl, options);

  return fetchAsObservable(url, options).pipe(
    catchError((error) => {
      if (error.status === 429) {
        const calls = getCalls();
        calls.length = 0;
        return fetchAsObservable(url, options);
      } else {
        return throwError(error);
      }
    })
  );
}

export function getVersionId(previousVersion = false) {
  const re = /\/versions\/([^\/]+)(\/.+)*/g;
  const result = re.exec(window.location.hash);

  if (isEmpty(result)) {
    asyncStacktrace((error) => {
      throw error();
    }, "Cannot find version id from URL");

    return null;
  }

  let versionId = result[1];

  if (previousVersion) {
    let rev = Number(versionId.split("-")[1]);
    rev = rev === 0 ? rev : rev - 1;
    versionId = versionId.split("-")[0] + "-" + rev;
  }

  return versionId;
}

export function resolveUrl(...args) {
  let [url, options] = args;
  options = options || {};

  if (!options.noVersions) {
    const versionId = getVersionId(!!options.previousVersion);
    url = versionId
      ? canopyUrls.getWebUrl() + `/wg/versions/${versionId}/${url}`
      : canopyUrls.getWebUrl() + `/wg/${url}`;
  } else {
    url = canopyUrls.getWebUrl() + "/wg/" + url;
  }

  return url;
}

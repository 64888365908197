import * as types from "src/tax-forms/form/tax-form.types.js";
import { ruleConsistentListMembers } from "src/tax-forms/form/fields.helper.js";
import { cloneDeep, find } from "lodash";

let defaultState = {};

const defaultForm = {};
const defaultValidation = {};
const defaultFormCoords = { fields: {} };
const defaultLogicForm = {};
const defaultAddendumCoords = [];

function formReducer(state = defaultForm, action) {
  if (action.type === "UPDATED_TAX_FORM_FIELD") {
    return {
      ...state,
      changeId: action.changeId,
      fields: {
        ...state.fields,
        [action.fieldName]: action.field,
      },
    };
  }

  if (action.type === "UPDATED_LIST_MEMBER") {
    return {
      ...state,
      fields: ruleConsistentListMembers(
        state.fields,
        action.fieldPrefix,
        action.field
      ),
    };
  }

  if (action.type === "UPDATED_ADDENDUM_FIELD") {
    return {
      ...state,
      changeId: action.changeId,
      addendums: (state.addendums || []).map((addendum) => {
        if (addendum.name === action.addendumName) {
          return {
            ...addendum,
            fields: {
              ...addendum.fields,
              [action.fieldName]: action.field,
            },
          };
        } else return addendum;
      }),
    };
  }

  if (action.type === "UPDATED_ADDENDUM_LIST_MEMBER") {
    let addendums = state.addendums;

    let targetAddendum = find(
      addendums,
      (addendum) => addendum.name === action.addendumName
    );

    if (targetAddendum) {
      targetAddendum.fields = ruleConsistentListMembers(
        targetAddendum.fields,
        action.fieldPrefix,
        action.field
      );
    }

    return {
      ...state,
      addendums,
    };
  }

  if (action.type === types.FORM_UPDATED) return cloneDeep(action.form);

  if (action.type === types.UPDATED_CHANGE_ID) {
    return {
      ...state,
      changeId: action.changeId,
    };
  }

  if (action.type === types.ADDENDUMS_UPDATED) {
    return {
      ...state,
      addendums: action.addendums,
    };
  }

  if (action.type === "UNLOADED_TAXFORM") {
    return defaultForm;
  }

  return state;
}

function oldFormReducer(state = null, action) {
  if (action.type === "LOADED_OLD_TAXFORM") {
    return action.form;
  }

  if (action.type === "UNLOADED_TAXFORM") {
    return null;
  }

  return state;
}

function formCoordsReducer(state = defaultFormCoords, action) {
  if (action.type === types.COORDS_LOADED) {
    return action.formCoords;
  }
  return state;
}

function logicForm(state = defaultLogicForm, action) {
  if (action.type === types.LOGIC_FORM_CHANGED) {
    return action.logicForm;
  }

  if (action.type === types.ADDENDUMS_UPDATED) {
    return {
      ...state,
      addendums: action.addendums,
    };
  }

  if (action.type === types.UPDATED_CHANGE_ID) {
    return {
      ...state,
      changeId: action.changeId,
    };
  }

  return state;
}

function addendumCoordsReducer(state = defaultAddendumCoords, action) {
  if (action.type === types.ADDENDUM_COORDS_LOADED) {
    return action.coords;
  }

  return state;
}

function validationReducer(state = defaultValidation, action) {
  if (action.type === types.VALIDATION_STARTED) {
    return {
      ...state,
      displayed: true,
      invalidFields: action.fields,
      selected: null,
    };
  }

  if (action.type === types.VALIDATION_CLOSED) {
    return {
      ...state,
      invalidFields: null,
      displayed: false,
      selected: null,
    };
  }

  if (action.type === types.VALIDATION_FORM_SELECTED) {
    return {
      ...state,
      selected: state.selected === action.form ? null : action.form,
    };
  }

  return state;
}

function addendumDialogReducer(state, action) {
  if (action.type === "ADDENDUM_DIALOG_DISPLAYED") {
    return action.addendum;
  }

  if (action.type === "ADDENDUM_DIALOG_CLOSED") {
    return null;
  }

  return state;
}

export default function (state = defaultState, action) {
  return {
    form: formReducer(state.form, action),
    oldForm: oldFormReducer(state.oldForm, action),
    formCoords: formCoordsReducer(state.formCoords, action),
    logicForm: logicForm(state.logicForm, action),
    addendumCoords: addendumCoordsReducer(state.addendumCoords, action),
    validation: validationReducer(state.validation, action),
    addendumDialog: addendumDialogReducer(state.addendumDialog, action),
  };
}

import React from "react";
import {
  CpButton,
  CpLoader,
  modalService,
  CpModal,
} from "canopy-styleguide!sofe";
import styles from "./manage-team-members.styles.css";
import { editUser } from "./manage-team-members.resource.js";
import { successToast, warningToast } from "toast-service!sofe";
import { asyncStacktrace, catchSyncStacktrace } from "auto-trace";

export class EditSme extends React.Component {
  state = {
    saving: false,
    role: this.props.member.role,
    product: this.props.member.product,
  };

  editUserClick = () => {
    this.setState((prevState, props) => {
      this.props.cancelWhenUnmounted(
        editUser(prevState, props).subscribe(
          (user) => {
            successToast(`${props.member.email} has been successfully edited!`);
            props.onNext({ prevState, user });
            props.onCompleted();
          },
          asyncStacktrace((error) => {
            warningToast("Error editing user");
            error.showToast = false;
            catchSyncStacktrace(error);
            props.onCompleted();
          })
        )
      );
      return { saving: true };
    });
  };

  render() {
    const { saving, role, product } = this.state;

    return (
      <>
        <CpModal.Header
          title={
            this.props.member.name !== this.props.member.email
              ? this.props.member.name
              : "Edit team member"
          }
        />

        {saving ? (
          <div className={styles.loader}>
            <CpLoader />
          </div>
        ) : (
          <>
            <CpModal.Body>
              <div className="cps-form-horizontal">
                <div className="cps-form-group">
                  <label
                    style={{
                      fontWeight: "bold",
                      margin: "0 16px 0 8px",
                      fontSize: "1.4rem",
                    }}
                  >
                    Email:
                  </label>
                  <span style={{ marginTop: "3px" }}>
                    {this.props.member.email}
                  </span>
                </div>
              </div>
              <div style={{ marginTop: 24 }}>
                <div style={{ fontWeight: "bold" }}>
                  Product they will be working on:
                </div>
                <div className={styles.option}>
                  <label className="cps-radio">
                    <input
                      type="radio"
                      name="product"
                      value="Tax Resolution"
                      checked={product === "Tax Resolution"}
                      onChange={(e) =>
                        this.setState({ product: e.target.value })
                      }
                    />
                    <span>Tax Resolution</span>
                  </label>
                </div>
                <div className={styles.option}>
                  <label className="cps-radio">
                    <input
                      type="radio"
                      name="product"
                      value="Tax Preparation"
                      checked={product === "Tax Preparation"}
                      onChange={(e) =>
                        this.setState({ product: e.target.value })
                      }
                    />
                    <span>Tax Preparation</span>
                  </label>
                </div>
              </div>
              <div style={{ marginTop: 24 }}>
                <div style={{ fontWeight: "bold" }}>Their role:</div>
                <div className={styles.option}>
                  <label className="cps-radio">
                    <input
                      type="radio"
                      name="role"
                      value="Intern/Senior associate"
                      checked={role === "Intern/Senior associate"}
                      onChange={(e) => this.setState({ role: e.target.value })}
                    />
                    <span>Intern/Senior associate</span>
                  </label>
                </div>
                <div className={styles.option}>
                  <label className="cps-radio">
                    <input
                      type="radio"
                      name="role"
                      value="Manager"
                      checked={role === "Manager"}
                      onChange={(e) => this.setState({ role: e.target.value })}
                    />
                    <span>Manager</span>
                  </label>
                </div>
              </div>
            </CpModal.Body>
            <CpModal.Footer>
              <CpButton
                onClick={this.editUserClick}
                disabled={
                  role === this.props.member.role &&
                  product === this.props.member.product
                }
              >
                Edit user
              </CpButton>
              <CpButton
                btnType="flat"
                className="cp-ml-16"
                onClick={this.props.onCompleted}
              >
                Cancel
              </CpButton>
            </CpModal.Footer>
          </>
        )}
      </>
    );
  }
}

export default function showEditSmeModal(member) {
  return modalService.render(EditSme, { member });
}

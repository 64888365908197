import React from "react";
import { findIndex } from "lodash";
import Loader from "src/common/loader.component.js";
import { getDiff } from "src/versions/versions.resource.js";
import DiffAccordion from "src/versions/diff-accordion.component.js";
import {
  getQuestionLink,
  getSourceFormLink,
  getGroupLink,
  getTaxFormLink,
  getReportLink,
  getSharedValuesLink,
} from "src/versions/change.helpers.js";

export default class DiffDialog extends React.Component {
  constructor(props) {
    super(props);
    const { version, revision, actions } = props;
    const { revisions } = version;

    let index = findIndex(revisions, revision);

    this.state = {
      selected: revisions[index - 1] || revisions[0],
      diffData: null,
    };
  }

  componentDidMount() {
    this.loadDiff();
  }

  render() {
    const { version, revision, actions } = this.props;
    const { revisions } = version;
    const { selected } = this.state;

    return (
      <div className="cps-overlay-modal">
        <div className="cps-overlay-modal__content">
          <div className="cps-btn-icon cps-pull-right">
            <a onClick={actions.closeDiffDialog} className="cps-link">
              <span className="cps-icon cps-icon-close" />
            </a>
          </div>
          <div className="cps-overlay-modal__content__header">
            <h2>What Changed?</h2>
          </div>
          <div className="cps-overlay-modal__content__body">
            <div>
              <i>
                <b>{revision.name}</b>
              </i>{" "}
              and
              <select
                value={selected.id}
                onChange={this.updatedSelection.bind(this)}
                style={{
                  width: "300px",
                  position: "relative",
                  bottom: "6px",
                  marginLeft: "12px",
                }}
                className="cps-form-control cps-inline-block"
              >
                {revisions.map(toRevisionOption)}
              </select>
            </div>
            <div style={{ minHeight: "200px" }}>
              {!this.state.selected && "Please select a valid revision"}
              {this.state.selected && !this.state.diffData && (
                <Loader
                  page={true}
                  style={{
                    position: "relative",
                    top: "114px",
                    left: "180px",
                  }}
                />
              )}
              {this.state.selected && this.state.diffData && this.getDiff()}
            </div>
            <div style={{ marginTop: "64px" }}>
              <button
                className="cps-btn +primary"
                onClick={actions.closeDiffDialog}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  getDiff() {
    const { questions, groups, reports } = this.state.diffData;
    const sourceForms = this.state.diffData["source-forms"];
    const sharedValues = this.state.diffData["shared-values"];
    const taxForms = this.state.diffData["tax-forms"];

    return (
      <div>
        {!questions.length &&
        !sourceForms.length &&
        !groups.length &&
        !reports.length ? (
          <div style={{ paddingTop: 50 }}>
            <center>
              There are no differences with the selected revisions!
            </center>
          </div>
        ) : null}
        <DiffAccordion
          getLink={getQuestionLink.bind(
            null,
            this.props.version,
            this.props.revision
          )}
          name="Questions"
          list={questions}
          keyProp={"name"}
        />
        <DiffAccordion
          getLink={getSourceFormLink.bind(
            null,
            this.props.version,
            this.props.revision
          )}
          name="Source Forms"
          list={sourceForms}
          keyProp={"formId"}
        />
        <DiffAccordion
          getLink={getGroupLink.bind(
            null,
            this.props.version,
            this.props.revision
          )}
          name="Groups"
          list={groups}
          keyProp={"id"}
        />
        <DiffAccordion
          getLink={getSharedValuesLink.bind(
            null,
            this.props.version,
            this.props.revision
          )}
          name="Shared Values"
          list={sharedValues}
          keyProp={"id"}
        />
        <DiffAccordion
          getLink={getTaxFormLink.bind(
            null,
            this.props.version,
            this.props.revision
          )}
          name="Tax Forms"
          list={taxForms}
          keyProp={"id"}
        />
        <DiffAccordion
          getLink={getReportLink.bind(
            null,
            this.props.version,
            this.props.revision
          )}
          name="Reports"
          list={reports}
          keyProp={"id"}
        />
      </div>
    );
  }

  updatedSelection(e) {
    const { version, actions } = this.props;

    const revision = version.revisions.find(
      (revision) => revision.id === e.target.value
    );

    this.setState({
      selected: revision,
      diffData: null,
    });

    setTimeout(() => this.loadDiff());
  }

  loadDiff() {
    const { version, revision, actions } = this.props;

    if (this.state.selected) {
      getDiff(
        `${version.id}-${revision.id}`,
        `${version.id}-${this.state.selected.id}`
      ).subscribe((diffData) => {
        this.setState({ diffData });
      });
    }
  }
}

function toRevisionOption(revision) {
  return (
    <option key={revision.id} value={revision.id}>
      {revision.name}
    </option>
  );
}

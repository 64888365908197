const DEFAULT_STATE = null;

export default function subSectionsReducer(state = DEFAULT_STATE, action) {
  if (action.type === "UNLOADED_SUB_SECTION") {
    return DEFAULT_STATE;
  }

  if (action.type === "LOADED_SUB_SECTION") {
    return action.subSection;
  }

  return state;
}

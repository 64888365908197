export const FORM_LOADED = "FORM_LOADED";
export const ELEMENT_UPDATED = "ELEMENT_UPDATED";
export const ELEMENT_DELETED = "ELEMENT_DELETED";

export const FORM_UPDATED = "FORM_UPDATED";
export const COORDS_LOADED = "COORDS_LOADED";
export const UPDATED_CHANGE_ID = "UPDATED_CHANGE_ID";

export const LOGIC_FORM_CHANGED = "LOGIC_FORM_CHANGED";

export const ADDENDUMS_UPDATED = "ADDENDUMS_UPDATED";
export const ADDENDUM_COORDS_LOADED = "ADDENDUM_COORDS_LOADED";

export const VALIDATION_STARTED = "VALIDATION_STARTED";
export const VALIDATION_CLOSED = "VALIDATION_CLOSED";
export const VALIDATION_FORM_SELECTED = "VALIDATION_FORM_SELECTED";

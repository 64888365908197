import React from "react";
import { func, bool, any } from "prop-types";
import { Scoped, always } from "kremling";
import { CpButton, CpModal } from "canopy-styleguide!sofe";

import css from "src/versions2/versions-styles.krem.css";

export default class CreateReleaseCandidateModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: this.props.targetRevision.name + " RC",
      description: this.props.targetRevision.description,
    };
  }

  static propTypes = {
    targetRevision: any,
    onModalClose: func,
    isHotFix: bool,
    onCreateReleaseCandidate: func,
    isNameUnique: func,
  };

  render() {
    const showNameError = !this.props.isNameUnique(this.state.name, "-1");

    return (
      <CpModal show onClose={this.props.onModalClose}>
        <CpModal.Header
          title={`Create ${
            this.props.isHotFix ? "Hot Fix" : "Standard"
          } Release Candidate`}
        />
        <CpModal.Body>
          <Scoped css={css}>
            <div
              className={always("cps-form-group").maybe(
                "cps-has-error",
                showNameError
              )}
            >
              <label htmlFor="releaseCandidateName">
                Release candidate name
              </label>
              <input
                className="cps-form-control"
                placeholder="Enter name"
                type="text"
                value={this.state.name}
                onChange={(event) => {
                  this.setState({ name: event.target.value });
                }}
              />
              {showNameError && (
                <>
                  <span
                    aria-hidden="true"
                    className="cps-icon-error cps-form-control-feedback"
                  />
                  <span className="cps-error-block">Name must be unique!</span>
                </>
              )}
            </div>
            <div className="cps-form-group">
              <label htmlFor="releaseCandidateDescription">
                Release candidate description
              </label>
              <textarea
                className="cps-form-control modal-textarea"
                placeholder="Enter description"
                value={this.state.description}
                onChange={(event) => {
                  this.setState({ description: event.target.value });
                }}
              />
            </div>
          </Scoped>
        </CpModal.Body>
        <CpModal.Footer>
          <CpButton
            disabled={showNameError}
            btnType="primary"
            onClick={() => {
              this.props.onCreateReleaseCandidate(
                this.props.targetRevision,
                this.state.name,
                this.state.description
              );
            }}
          >
            Create
          </CpButton>
          <CpButton
            btnType="flat"
            className="cp-ml-16"
            onClick={this.props.onModalClose}
          >
            Cancel
          </CpButton>
        </CpModal.Footer>
      </CpModal>
    );
  }
}

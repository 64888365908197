import { orderBy } from "lodash";
import { columnSort } from "src/tax-forms/list/tax-form-list-enhanced.helpers.js";

const defaultState = {
  list: [],
  loaded: false,
  dialog: null,
  filter: null,
  searchQuery: null,
};

export default function (state = defaultState, action) {
  switch (action.type) {
    case "TAX_FORM_LIST_LOADED":
      return {
        ...state,
        list: action.forms,
        loaded: true,
      };
    case "TAX_FORM_LIST_CLEARED":
      return {
        ...state,
        list: [],
        loaded: false,
      };
    case "OPENED_TAX_FORM_LIST_DIALOG":
      return {
        ...state,
        dialog: action.form,
      };
    case "CLOSED_TAX_FORM_LIST_DIALOG":
      return {
        ...state,
        dialog: null,
      };
    case "SORT":
      const newList = state.list;
      return {
        ...state,
        list: columnSort(
          newList,
          action.payload.columnName,
          action.payload.direction
        ),
      };
    case "DELETE":
      return {
        ...state,
        list: state.list.filter((item) => item.id !== action.payload),
      };
    case "FILTER_FORMS":
      return {
        ...state,
        filter: action.payload,
      };
    case "UPDATE_SEARCH_QUERY":
      return {
        ...state,
        searchQuery: action.payload.searchQuery,
      };
    case "UPDATE_SEARCH_RESULTS":
      return {
        ...state,
        list: action.payload.forms,
      };
    default:
      return state;
  }
}

import * as types from "src/uploader/uploader.types.js";

const defaultState = {
  displayed: false,
  fileToAdd: null,
  isValid: false,
  overWriting: null,
};

export default function (state = defaultState, action) {
  if (action.type === types.DISPLAYED_UPLOADER) {
    return {
      ...state,
      displayed: true,
      folderId: action.folderId,
      formToReplace: action.formToReplace,
    };
  }

  if (action.type === types.CLOSED_UPLOADER) {
    return {
      ...state,
      displayed: false,
      folderId: null,
      fileToAdd: null,
      formToReplace: null,
      isValid: false,
    };
  }

  if (action.type === types.ADDED_FILE) {
    return {
      ...state,
      fileToAdd: action.file[0],
    };
  }

  if (action.type === types.FORM_CHANGED) {
    return {
      ...state,
      isValid: action.isValid,
    };
  }

  if (action.type === types.OVERWRITING_FORM) {
    return {
      ...state,
      overWriting: action.form,
    };
  }

  if (action.type === types.CLEARED_FORM) {
    return { ...defaultState, displayed: true };
  }

  return state;
}

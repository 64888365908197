import * as types from "src/source-forms/groups/groups.types.js";

const defaultGroupsState = null;
const defaultState = {};
const defaultOldGroups = null;

function groupsReducer(state = defaultGroupsState, action) {
  if (action.type === types.LOADED_GROUPS) {
    return action.groups;
  }

  if (action.type === "UNLOADED_GROUPS") {
    return defaultGroupsState;
  }

  return state;
}

function oldGroupsReducer(state = defaultOldGroups, action) {
  if (action.type === "LOADED_GROUPS") {
    return action.groups;
  }

  if (action.type === "UNLOADED_OLD_GROUPS") {
    return defaultOldGroups;
  }

  return state;
}

export default function (state = defaultState, action) {
  return {
    loadedGroups: groupsReducer(state.loadedGroups, action),
    oldGroups: oldGroupsReducer(state.oldGroups, action),
  };
}

import { find, without } from "lodash";
import * as types from "src/questions/question-bank.types.js";
let stateDefault = {};
let folderStateDefault = [];
let questionsDefault = [];
let activeFolderDefault = null;
let activeQuestionDefault = null;
let referencesForQuestionDefault = {};
let searchQuestionsDefault = [];

function foldersReducer(state = folderStateDefault, action) {
  if (action.type === types.LOADED_QUESTION_FOLDERS) {
    return action.folders;
  }

  return state;
}

function oldFoldersReducer(state = null, action) {
  if (action.type === "LOADED_OLD_QUESTION_FOLDERS") {
    return action.folders;
  }

  if (action.type === types.INITIALIZED_QUESTION_BANK) {
    return null;
  }

  return state;
}

function oldQuestionsReducer(state = null, action) {
  if (action.type === "LOADED_OLD_QUESTIONS") {
    return action.questions;
  }

  if (action.type === types.INITIALIZED_QUESTION_BANK) {
    return null;
  }

  return state;
}

function activeFolderReducer(state = activeFolderDefault, action) {
  if (action.type === types.CHANGED_ACTIVE_FOLDER) {
    return action.folderId;
  }

  return state;
}

function questionsReducer(state = questionsDefault, action) {
  if (action.type === types.CREATED_QUESTION) {
    return [
      {
        id: action.questionId,
        type: action.questionType,
        name: "",
        newQuestion: true,
      },
      ...state,
    ];
  }

  if (action.type === types.CHANGED_ACTIVE_FOLDER) {
    return action.questions;
  }

  if (action.type === types.CHANGED_ACTIVE_QUESTION) {
    let newQuestion = find(state, { newQuestion: true });

    if (!action.questionId) {
      return without(state, newQuestion);
    }
  }

  return state;
}

function activeQuestionReducer(state = activeQuestionDefault, action) {
  if (action.type === types.CHANGED_ACTIVE_QUESTION) {
    return action.questionId;
  }

  if (action.type === types.CREATED_QUESTION) {
    return action.questionId;
  }

  if (
    action.type === types.CHANGED_ACTIVE_FOLDER ||
    action.type === types.LOADED_SEARCHED_QUESTIONS
  ) {
    return null;
  }

  return state;
}

function referencesForQuestionReducer(
  state = referencesForQuestionDefault,
  action
) {
  if (action.type === types.LOADED_QUESTION_REFERENCES) {
    return { references: action.references, name: action.name };
  }

  if (action.type === types.CLEARED_QUESTION_REFERENCES) {
    return {};
  }

  return state;
}

function searchQuestionsReducer(state = searchQuestionsDefault, action) {
  if (action.type === types.LOADED_SEARCHED_QUESTIONS) {
    return action.questions;
  }

  if (action.type === types.INITIALIZED_QUESTION_BANK) {
    return [];
  }

  return state;
}

function searchOldQuestionsReducer(state = null, action) {
  if (action.type === "LOADED_SEARCHED_OLD_QUESTIONS") {
    return action.questions;
  }

  if (action.type === types.INITIALIZED_QUESTION_BANK) {
    return null;
  }

  return state;
}

export default function questionBankReducer(state = stateDefault, action) {
  return {
    folders: foldersReducer(state.folders, action),
    oldFolders: oldFoldersReducer(state.oldFolders, action),
    activeFolder: activeFolderReducer(state.activeFolder, action),
    questions: questionsReducer(state.questions, action),
    oldQuestions: oldQuestionsReducer(state.oldQuestions, action),
    activeQuestion: activeQuestionReducer(state.activeQuestion, action),
    referencesForQuestion: referencesForQuestionReducer(
      state.referencesForQuestion,
      action
    ),
    searchQuestions: searchQuestionsReducer(state.searchQuestions, action),
    searchOldQuestions: searchOldQuestionsReducer(
      state.searchOldQuestions,
      action
    ),
  };
}

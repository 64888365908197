import React from "react";
import { Route } from "react-router-dom";
import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import {
  connectRouter,
  ConnectedRouter,
  routerMiddleware,
} from "connected-react-router";
import { catchAsyncStacktrace } from "auto-trace";
import { ErrorBoundary } from "error-logging!sofe";

import App from "src/app.component.js";
const history = require("history").createHashHistory();

// Import reducers
import userReducer from "src/user/user.reducer.js";
import QuestionBankReducer from "src/questions/question-bank.reducer.js";
import SourceFormReducer from "src/source-forms/source-form.reducer.js";
import taxFormListReducer from "src/tax-forms/list/tax-form-list.reducer.js";
import taxFormReducer from "src/tax-forms/form/tax-form.reducer.js";
import sortingColumnsReducer from "src/tax-forms/list/sorting-columns.reducer.js";
import versionsReducer from "src/versions/versions.reducer.js";
import uploaderReducer from "src/uploader/uploader.reducer.js";
import reportsReducer from "src/reports/reports.reducer.js";
import sharedValuesReducer from "src/shared-values/shared-values.reducer.js";
import { getVersions } from "./versions/versions.resource";

const reducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    ...createReducers(
      { user: userReducer },
      { questionBank: QuestionBankReducer },
      { sourceForms: SourceFormReducer },
      {
        taxForm: taxFormReducer,
        taxFormList: taxFormListReducer,
        sortingColumns: sortingColumnsReducer,
      },
      { versions: versionsReducer },
      { uploader: uploaderReducer },
      { reports: reportsReducer },
      { sharedValues: sharedValuesReducer }
    ),
  });

const middleware = [thunk, routerMiddleware(history)];

const store = createStore(
  reducer(history),
  undefined,
  compose(applyMiddleware(...middleware), (f) => f)
);

function createReducers(...reducerGroups) {
  let reducersMap = {};

  reducerGroups.forEach((group) => {
    for (let reducerName in group) {
      if (group.hasOwnProperty(reducerName)) {
        reducersMap[reducerName] = group[reducerName];
      }
    }
  });

  return reducersMap;
}

@ErrorBoundary({ featureName: "sme-ui" })
export default class Root extends React.Component {
  state = {
    versions: [],
  };

  componentDidMount() {
    this.getVersionsObs = getVersions().subscribe((versions) => {
      if (this && this.setState) {
        this.setState({ versions });
      }
    }, catchAsyncStacktrace());
  }

  render() {
    const { versions } = this.state;
    if (!versions || !versions.length) return null;
    return (
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <Route
            path="/sme"
            render={(props) => <App {...props} versions={versions} />}
          />
        </ConnectedRouter>
      </Provider>
    );
  }
}

import React from "react";
import * as types from "src/tax-forms/form/tax-form.types.js";
import { nameilizer, dateifinizer } from "src/common/name-and-date.helper.js";
import { startCase, isEmpty } from "lodash";

export const defaultState = {
  columns: [
    {
      name: "name",
      width: "20%",
      columnLabel: "Form",
      centered: false,
      sortable: true,
      getDisplayVal: (form) => <span data-testid="form-name">{form.name}</span>,
    },
    {
      name: "title",
      width: "20%",
      columnLabel: "Label",
      centered: false,
      sortable: true,
      getDisplayVal: (form) => form.title,
    },
    {
      name: "type",
      width: "20%",
      columnLabel: "Type",
      centered: false,
      sortable: true,
      getDisplayVal: (form) =>
        !isEmpty(form.subType) ? startCase(form.subType) : startCase(form.type),
    },
    {
      name: "sequence",
      width: "15%",
      columnLabel: "Seq. No.",
      centered: true,
      sortable: true,
      getDisplayVal: (form) => (form.sequence ? form.sequence : "--"),
    },
    {
      name: "pdf",
      width: "10%",
      columnLabel: "Download",
      centered: true,
      sortable: false,
    },
    {
      name: "status",
      width: "10%",
      columnLabel: "Completed",
      centered: true,
      sortable: false,
    },
    {
      name: "tools",
      width: "25%",
      columnLabel: "",
      centered: false,
      sortable: false,
    },
  ],
  sort: {
    columnName: "form",
    direction: "asc",
  },
};

function columnsReducer(state, action) {
  switch (action) {
    default:
      return state;
  }
}

function sortReducer(state, action) {
  switch (action.type) {
    case "SORT":
      return {
        ...state,
        columnName: action.payload.columnName,
        direction: action.payload.direction,
      };
    default:
      return state;
  }
}

export default function sortingColumns(state = defaultState, action) {
  return {
    columns: columnsReducer(state.columns, action),
    sort: sortReducer(state.sort, action),
  };
}

import { findIndex } from "lodash";
import * as types from "src/reports/reports.types.js";

const defaultState = {
  reportsList: [],
  reportBeingEdited: null,
  oldReport: null,
  reportDialogDisplayed: false,
};

export default function reportsReducer(state = defaultState, action) {
  if (action.type === types.LOADED_REPORTS_INDEX) {
    return {
      ...state,
      reportsList: action.reports,
    };
  }

  if (action.type === "LOADED_OLD_REPORT") {
    return {
      ...state,
      oldReport: action.report,
    };
  }

  if (action.type === "UNLOADED_REPORT") {
    return {
      ...state,
      oldReport: null,
    };
  }

  if (action.type === types.LOADED_REPORT) {
    return {
      ...state,
      reportBeingEdited: action.reports,
      reportsList: mergeReport(state.reportsList, action.reports),
    };
  }

  if (action.type === types.SHOWED_EDIT_DIALOG) {
    return {
      ...state,
      reportDialogDisplayed: true,
    };
  }

  if (action.type === types.CLOSED_EDIT_DIALOG) {
    return {
      ...state,
      reportDialogDisplayed: false,
    };
  }

  return state;
}

function mergeReport(reportList, reportToReplace) {
  return reportList.map((report) =>
    report.id === reportToReplace.id ? reportToReplace : report
  );
}

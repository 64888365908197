

(function() {
  
  function _insertSvgIcons() {
    const svg = document.createRange().createContextualFragment(`<svg xmlns="http://www.w3.org/2000/svg" style="display: none;"><symbol id="fa-code" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M250.7 435l-27.2-7.9c-2.9-.8-4.7-4-3.9-6.9l98.6-339.3c.8-2.9 4-4.7 6.9-3.9l27.2 7.9c2.9.8 4.7 4 3.9 6.9l-98.6 339.3c-.8 3.1-3.8 4.7-6.9 3.9zm-58.2-84.8l19-20.3c2.2-2.3 2-6-.4-8.1L136.9 256l74.3-65.9c2.4-2.1 2.5-5.7.4-8.1l-19-20.3c-2.1-2.2-5.7-2.4-7.9-.3l-96.5 90.4c-2.4 2.2-2.4 6 0 8.2l96.5 90.4c2.1 2.2 5.6 2.1 7.8-.2zm198.9.3l96.4-90.4c2.4-2.2 2.4-5.9 0-8.2l-96.4-90.4c-2.2-2.1-5.8-2-7.9.3l-19 20.3c-2.2 2.3-2 5.9.4 8.1L439 256l-74.3 65.9c-2.4 2.1-2.5 5.7-.4 8.1l19 20.3c2.3 2.2 5.8 2.3 8.1.2z"/></symbol><symbol id="fa-allocations" viewBox="0 0 22 17" xmlns="http://www.w3.org/2000/svg"><g stroke="#777" fill-rule="evenodd"><rect stroke-width="1.5" x="7.75" y=".75" width="6.5" height="3.5" rx="1"/><path d="M11 4.5v4" stroke-width="1.2" stroke-linecap="square"/><path d="M11 8.5H3" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/><path d="M11 8.5h8M19 8.5V12" stroke-width="1.2" stroke-linecap="round"/><path d="M3 8.5V12" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/><path d="M11 8.5v3" stroke-width="1.2" stroke-linecap="square"/><rect stroke-width="1.5" x=".75" y="12.75" width="4.5" height="3.5" rx="1"/><rect stroke-width="1.5" x="16.75" y="12.75" width="4.5" height="3.5" rx="1"/><rect stroke-width="1.5" x="8.75" y="12.75" width="4.5" height="3.5" rx="1"/></g></symbol></svg>`);
    if (document.body.childNodes[0]) {
      document.body.insertBefore(svg, document.body.childNodes[0]);  
    } else {
      document.body.appendChild(svg);
    }
  }
  if (/comp|inter|loaded/.test(document.readyState)) {
    _insertSvgIcons();
  } else {
    document.addEventListener('DOMContentLoaded', _insertSvgIcons);
  }
})();
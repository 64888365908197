import * as types from "src/source-forms/summary-table/summary-table.types.js";

const defaultState = {
  questionDialogDisplayed: false,
};

export default function SummaryTableReducer(state = defaultState, action) {
  if (action.type === types.DISPLAYED_SUMMARY_TABLE_QUESTION_MODAL) {
    return {
      questionDialogDisplayed: true,
    };
  }

  if (action.type === types.HID_SUMMARY_TABLE_QUESTION_MODAL) {
    return {
      questionDialogDisplayed: false,
    };
  }

  return state;
}

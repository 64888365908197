import { pluck } from "rxjs/operators";

import fetcher from "src/common/fetcher.helper.js";
import { removeYearIfTaxRes } from "src/versions/versions.helper.js";

export function getVersions() {
  return fetcher("versions", { noVersions: true }).pipe(pluck("versions"));
}

export function postVersion(version) {
  return fetcher("versions", {
    method: "POST",
    body: JSON.stringify({
      versions: removeYearIfTaxRes(version),
    }),
    noVersions: true,
  }).pipe(pluck("versions"));
}

export function deleteVersion(versionId) {
  return fetcher(`versions/${versionId}`, {
    method: "DELETE",
    noVersions: true,
  }).pipe(pluck("versions"));
}

export function putVersion(version) {
  return fetcher(`versions/${version.id}`, {
    method: "PUT",
    body: JSON.stringify({
      versions: removeYearIfTaxRes(version),
    }),
    noVersions: true,
  }).pipe(pluck("versions"));
}

export function getDiff(version, comparison) {
  return fetcher(`versions/${version}/diffs?oldVersion=${comparison}`, {
    noVersions: true,
  }).pipe(pluck("diffs"));
}

import React from "react";

export default function Loader({ page, style }) {
  return (
    <span
      style={style ? style : {}}
      className={`cps-loader ${page ? "+page" : ""}`}
    >
      <span />
      <span />
      <span />
    </span>
  );
}

export function ConditionalLoader({ elem, children }) {
  return elem ? (
    children()
  ) : (
    <div className="cps-card__body">
      <div
        style={{
          width: "118px",
          margin: "40px auto",
          height: "4px",
        }}
      >
        <Loader />
      </div>
    </div>
  );
}

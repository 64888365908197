import * as types from "src/source-forms/fixed-table/fixed-table.types.js";
import { cloneDeep } from "lodash";

import {
  getEmptyCoordinates,
  resizeColumns,
  updateTablesWithSharedValue,
  applyUpdatedQuestion,
  applyUpdatedSpecialAllocation,
} from "src/source-forms/fixed-table/fixed-table.helper.js";

const defaultState = {
  tables: {},
  hovered: {},
  draggingTable: null,
  dragging: null,
};

export default function (state = defaultState, action) {
  if (action.type === types.FIXED_TABLE_UPDATED_SHARED_VALUE) {
    return {
      ...state,
      tables: updateTablesWithSharedValue(state.tables, action.value),
    };
  }

  if (action.type === types.STARTED_EDITING_FIXED_TABLE) {
    return {
      ...state,
      tables: {
        ...state.tables,
        [action.tableName]: action.table.length
          ? action.table
          : getEmptyCoordinates(),
      },
    };
  }

  if (action.type === types.MODIFIED_EDITING_STATE) {
    return {
      ...state,
      tables: {
        ...state.tables,
        [action.tableName]: action.table,
      },
    };
  }

  if (action.type === types.FIXED_TABLE_STARTED_DRAGGING_COLUMN) {
    return {
      ...state,
      draggingTable: {
        name: action.tableName,
        table: [...state.tables[action.tableName]],
      },
      dragging: {
        anchor: action.anchor,
        column: action.column,
      },
    };
  }

  if (action.type === types.FIXED_TABLE_DRAGGED_COLUMN) {
    return {
      ...state,
      draggingTable: {
        name: action.tableName,
        table: resizeColumns(
          state.tables[action.tableName],
          state.dragging.column,
          state.dragging.anchor,
          action.clientX
        ),
      },
    };
  }

  if (action.type === types.FIXED_TABLE_STOPPED_DRAGGING_COLUMN) {
    return {
      ...state,
      tables: {
        ...state.tables,
        [action.tableName]: resizeColumns(
          state.tables[action.tableName],
          state.dragging.column,
          state.dragging.anchor,
          action.clientX
        ),
      },
      draggingTable: null,
      dragging: null,
    };
  }

  if (action.type === types.HOVERED_FIXED_TABLE) {
    return {
      ...state,
      hovered: {
        el: action.el,
        tableName: action.tableName,
        x: action.x,
        y: action.y,
      },
    };
  }

  if (action.type === types.STOPPED_HOVERING_FIXED_TABLE) {
    return {
      ...state,
      hovered: {},
    };
  }

  if (action.type === "UPDATED_QUESTION") {
    const clone = cloneDeep(state);
    return {
      ...clone,
      tables: applyUpdatedQuestion(clone, action),
    };
  }

  if (action.type === "UPDATED_SPECIAL_ALLOCATION") {
    return {
      ...state,
      tables: {
        ...state.tables,
        [action.question.name]: action.question.coordinates,
      },
    };
  }

  return state;
}

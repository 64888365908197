import React from "react";
import { object, array } from "prop-types";
import { Route, Switch } from "react-router-dom";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import "cpr-select/src/select.css";
import "cpr-multiselect/src/multi-selector.css";
import { getDraft } from "src/versions/versions.helper.js";
import Cancelable from "react-disposable-decorator";
import { concatMap, pluck } from "rxjs/operators";
import { get } from "lodash";
import { fetchAsObservable } from "fetcher!sofe";
import auth from "cp-client-auth!sofe";
import VersionManager from "src/versions2/version-manager.component.js";
import Version from "src/versions2/version.component.js";
import Load from "src/codeload.js";
import "src/global.style.css";
import "./ui/styles/main.scss";

const HelpContainer = Load(() => import("src/help/help.container.js"));
const SourceFormContainer = Load(() =>
  import("src/source-forms/source-form.container.js")
);
const TaxFormsBaseContainer = Load(() =>
  import("src/tax-forms/tax-forms-base.container.js")
);
const ImportContainer = Load(() => import("src/importer/import.container.js"));
const ReportsBaseContainer = Load(() =>
  import("src/reports/reports-base-container.js")
);
const SharedValuesContainer = Load(() =>
  import("src/shared-values/shared-values.container.js")
);
const ViewChanges = Load(() =>
  import("src/workspaces/view-changes.component.js")
);
const ManageTeamMembers = Load(() =>
  import("src/manage-team-members/manage-team-members.component.js")
);
const QuestionEditor = Load(() =>
  import("src/questions/question-editor.component.js")
);
const PdfRollover = Load(() =>
  import("src/tax-forms/pdf-rollover/pdf-rollover.component.js")
);

@Cancelable
export default class App extends React.Component {
  static propTypes = {
    versions: array,
  };
  static contextTypes = {
    router: object.isRequired,
  };

  componentDidUpdate(prevProps) {
    if (
      get(this.props, "location.search") === "?changes" &&
      get(prevProps, "location.search") !== get(this.props, "location.search")
    ) {
      this.redirectCheck();
    }
  }

  componentDidMount() {
    this.redirectCheck();
  }

  /*
    only redirect if we're on a page that requires a version
    to be set and it's not there for some reason
  */
  redirectCheck = () => {
    const { versions } = this.props;
    get(location.hash.match(/(\w+-\d+)|reviews|help|master|manage/), "[0]") ||
      this.props.cancelWhenUnmounted(
        auth
          .getLoggedInUserAsObservable()
          .pipe(
            concatMap((user) => fetchAsObservable(`/sme-users/${user.id}`)),
            pluck("sme-users")
          )
          .subscribe((sme) => {
            const v = getDraft(versions, sme.product);
            return this.props.history.push(
              `/sme/versions/${v}/source-form-builder/groups`
            );
          })
      );
    return null;
  };

  render() {
    const { versions } = this.props;
    return (
      <DndProvider backend={HTML5Backend}>
        <div>
          <Switch>
            <Route exact path="/sme/" render={() => this.redirectCheck()} />
            <Route
              path="/sme/manage-team-members"
              component={ManageTeamMembers}
            />
            <Route exact path="/sme/versions" component={VersionManager} />
            <Route exact path="/sme/help" component={HelpContainer} />
            <Route exact path="/sme/versions/:versionId" component={Version} />
            <Route
              exact
              path="/sme/versions/:versionId/question-bank"
              render={(props) => (
                <QuestionEditor {...props} versions={versions} />
              )}
            />
            <Route
              exact
              path="/sme/versions/:versionId/question-bank/:folderId"
              render={(props) => (
                <QuestionEditor {...props} versions={versions} />
              )}
            />
            <Route
              exact
              path="/sme/versions/:versionId/question-bank/:folderId/question/:questionId"
              render={(props) => (
                <QuestionEditor {...props} versions={versions} />
              )}
            />
            <Route
              path="/sme/versions/:versionId/source-form-builder"
              render={(props) => (
                <SourceFormContainer {...props} versions={versions} />
              )}
            />
            <Route
              path="/sme/versions/:versionId/tax-forms/pdf-rollover/:formId"
              component={PdfRollover}
            />
            <Route
              path="/sme/versions/:versionId/tax-forms"
              component={TaxFormsBaseContainer}
            />
            <Route
              path="/sme/versions/:versionId/importer"
              component={ImportContainer}
            />
            <Route
              path="/sme/versions/:versionId/reports"
              component={ReportsBaseContainer}
            />
            <Route
              path="/sme/versions/:versionId/shared-values"
              component={SharedValuesContainer}
            />
            <Route
              path="/sme/versions/:versionId/changes"
              component={ViewChanges}
            />
          </Switch>
        </div>
      </DndProvider>
    );
  }
}

import { fetchAsObservable } from "fetcher!sofe";
import urls from "canopy-urls!sofe";
import { pluck } from "rxjs/operators";

export function getSMEUsers() {
  return fetchAsObservable(`${urls.getWebUrl()}/wg/sme-users`).pipe(
    pluck("sme-users")
  );
}

export function inviteUser(state) {
  return fetchAsObservable("/sme-users", {
    method: "POST",
    body: JSON.stringify({
      "sme-users": {
        product: state.product === "Tax Resolution" ? "taxRes" : "taxPrep",
        email: state.email,
        url: window.location.origin,
        manager: state.role === "Manager",
      },
    }),
  }).pipe(pluck("sme-users"));
}

export function editUser(state, props) {
  return fetchAsObservable(`/sme-users/${props.member.id}`, {
    method: "PATCH",
    body: JSON.stringify({
      "sme-users": {
        product: state.product === "Tax Resolution" ? "taxRes" : "taxPrep",
        manager: state.role === "Manager",
        deactivated: false,
      },
    }),
  }).pipe(pluck("sme-users"));
}

export function deleteUser(member) {
  return fetchAsObservable(`/sme-users/${member.id}`, {
    method: "PATCH",
    body: JSON.stringify({
      "sme-users": {
        deactivated: true,
      },
    }),
  }).pipe(pluck("sme-users"));
}

export function undoDeleteUser(member) {
  return fetchAsObservable(`/sme-users/${member.id}`, {
    method: "PATCH",
    body: JSON.stringify({
      "sme-users": {
        deactivated: false,
      },
    }),
  }).pipe(pluck("sme-users"));
}

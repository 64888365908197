import React from "react";
import { catchAsyncStacktrace } from "auto-trace";
import { find, map, without, sortBy } from "lodash";
import { CpModal } from "canopy-styleguide!sofe";

import GovernmentCodeList from "./government-code-list.component";
import SubmissionTypeToggles from "./submission-type-toggles.component";
import { getGovernmentCodes } from "../versions.resource";

export default class VisibilityTogglesModal extends React.Component {
  state = {
    saving: false,
    activeGovernmentCode: "IRS",
    governmentCodes: [],
  };

  componentDidMount() {
    this.getGovernmentCodes();
  }

  getGovernmentCodes = () => {
    this.props.cancelWhenUnmounted(
      getGovernmentCodes(
        `${this.props.revision.version}-${this.props.revision.id}`
      ).subscribe((governmentCodesObj) => {
        const governmentAsArray = map(governmentCodesObj, (val, key) => ({
          code: key,
          name: val,
        }));

        const IRSElement = find(governmentAsArray, { code: "IRS" });
        const withoutFederal = without(governmentAsArray, IRSElement);

        const sortedCodes = sortBy(withoutFederal, "name");

        // Add back the IRS/federal code as the first element of the array
        sortedCodes.unshift(IRSElement);

        this.setState({
          governmentCodes: sortedCodes,
        });
      }, catchAsyncStacktrace())
    );
  };

  updateGovCode = (govCode) => {
    this.setState({ activeGovernmentCode: govCode });
  };

  render() {
    return (
      this.props.revision && (
        <>
          <CpModal.Header title="Visibility Toggles" />
          <CpModal.Body>
            <div className="cps-row">
              <GovernmentCodeList
                updateGovCode={this.updateGovCode}
                governmentCodes={this.state.governmentCodes}
                activeGovernmentCode={this.state.activeGovernmentCode}
              />
              <SubmissionTypeToggles
                governmentCode={this.state.activeGovernmentCode}
                version={`${this.props.revision.version}-${this.props.revision.id}`}
                cancelWhenUnmounted={this.props.cancelWhenUnmounted}
              />
            </div>
          </CpModal.Body>
        </>
      )
    );
  }
}

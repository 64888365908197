import {
  chain,
  includes,
  reduce,
  difference,
  keys,
  mapValues,
  capitalize,
} from "lodash";

export function pagify(fieldObj, widths, heights) {
  const pageArr = [];
  for (let key in fieldObj) {
    if (fieldObj.hasOwnProperty(key)) {
      let currPage = fieldObj[key].page - 1;
      pageArr[currPage] = [fieldObj[key]].concat(pageArr[currPage] || []);

      if (!pageArr[currPage].width) {
        pageArr[currPage].width = widths[currPage];
      }

      if (!pageArr[currPage].height) {
        pageArr[currPage].height = heights[currPage];
      }

      pageArr[currPage].diff = fieldObj.diff;
    }
  }
  return pageArr;
}

export function pixToPerc(pixels, dimension, page) {
  return Math.abs((pixels / pixelBase[dimension][page]) * 100);
}

let pixelBase;
export function setPixelBase(widths, heights) {
  pixelBase = {
    x: widths,
    y: heights,
  };
}

export function getFieldColor(currentElement, logicFocus, elem, highlight) {
  const rule = elem.rule;
  const isError = !elem.ruleNotRequired && !rule;

  return `rgba(${red(isError)}, ${green(isError)}, ${blue(logicFocus)}, ${alpha(
    isActive(elem.name, currentElement)
  )})`;

  function red(isError) {
    return !isError ? "100" : "220";
  }
  function green() {
    return !isError ? "220" : "100";
  }
  function blue(logic) {
    return logic ? "150" : highlight ? "255" : "80";
  }
  function alpha(current) {
    return current ? "1" : ".7";
  }
  function isActive(name, currentName) {
    if (name === currentElement) return true;

    if (!name || !currentName) return false;

    if (elementsAreMatching(name, currentName)) return true;
  }
}

/**
 * Update a property of each field within a map of fields that matches
 * a given field name. This makes changing "field_1" to also change "field_2"
 *
 * @param {Object} fields - an object map of fields
 * @param {String} fieldToChange - the property of the desired field to change
 * @param {Object} newFieldProperties - An object to spread upon the existing field
 *
 * @return {Object} A new object field map. We do not mutate the input.
 */
export function updateFieldsProperty(
  fields,
  fieldToChange,
  newFieldProperties
) {
  return reduce(fields, toFieldMapWithRules, {});

  function toFieldMapWithRules(all, field, fieldName) {
    return {
      ...all,
      [fieldName]: {
        ...field,
        ...(elementsAreMatching(fieldToChange, fieldName)
          ? newFieldProperties
          : {}),
      },
    };
  }
}

/**
 * Check if to strings match except by a trailing _##
 */
export function elementsAreMatching(name1, name2) {
  if (name1 === name2) return true;

  const name1Index = name1.lastIndexOf("_");
  const name2Index = name2.lastIndexOf("_");

  const num1 = name1.substring(name1Index + 1);
  const num2 = name2.substring(name2Index + 1);

  return (
    name1Index > 0 &&
    name2Index > 0 &&
    (!num1 || num1.match(/^\d+$/g)) &&
    (!num2 || num2.match(/^\d+$/g)) &&
    name1.substring(0, name1Index) === name2.substring(0, name2Index)
  );
}

export function fieldIsAListElement(name) {
  const index = name.lastIndexOf("_");
  const num = name.substring(index + 1);

  return num.match(/^\d+$/g);
}

export function removeUnusedFields(fields, coords) {
  let unusedKeys = difference(keys(fields), keys(coords));

  return {
    fields: reduce(fields, toMapWithoutUnusedFields, {}),
    unusedKeys,
  };

  function toMapWithoutUnusedFields(map, field, key) {
    if (includes(unusedKeys, key)) return map;
    else return { ...map, [key]: field };
  }
}

export function ruleConsistentListMembers(fields, prefix, data) {
  const testString = `^${prefix}{1}_\\d+$`;
  const testReg = new RegExp(testString);

  return mapValues(fields, (field, name) => {
    if (testReg.test(name)) {
      return {
        ...field,
        rule: data.rule,
        addendum: data.addendum,
        formatting: data.formatting,
        ruleNotRequired: data.ruleNotRequired,
      };
    } else {
      return field;
    }
  });
}

export function getMultiFormFieldOptionList(fields) {
  return chain(fields)
    .keys()
    .filter(
      (field) => !/^\d+$/.test(field.substring(field.lastIndexOf("_") + 1))
    )
    .map((field) => ({
      value: field,
      label: field,
    }))
    .value();
}

export function elementDiff(field, diff, fieldName) {
  const defaultDiff = [];
  if (!diff) return defaultDiff;

  return diff.reduce((acc, curr) => {
    if (!curr.path) {
      return "NEW_ELEMENT";
    }

    const [leading, set, name, key] = curr.path.split("/");

    return set === "fields" && name === fieldName
      ? [
          ...acc,
          {
            current:
              curr.op === "move" || curr.op === "copy"
                ? getLocation(curr.path)
                : getCurrentFieldValueFromDiff(field, curr.path),
            previous:
              curr.op === "move" || curr.op === "copy"
                ? getLocation(curr.from)
                : curr.value,
            changeType: curr.op,
            whatChanged: curr.path
              ? capitalize(getWhatChangedField(curr.path))
              : null,
          },
        ]
      : acc;
  }, defaultDiff);
}

const getCurrentFieldValueFromDiff = (field, path) =>
  field[getWhatChangedField(path)];

const getWhatChangedField = (path) => path.split("/").reverse()[0];

export const getLocation = (path) => {
  const [q, set, location, key] = path.split("/");
  return location;
};

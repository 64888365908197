import { cloneDeep, findIndex } from "lodash";

const DEFAULT_STATE = null;

export default function blocksReducer(state = DEFAULT_STATE, action) {
  if (action.type === "UNLOADED_BLOCK" || action.type === "UNLOADED_SECTION") {
    return DEFAULT_STATE;
  }

  if (action.type === "LOADED_BLOCK") {
    return action.block;
  }

  if (action.type === "UPDATED_QUESTION") {
    const block = cloneDeep(state);
    const question = action.question;

    block.questions[findIndex(block.questions, { name: question.name })] =
      question;

    return block;
  }

  return state;
}

import React from "react";
import { Scoped, k, a } from "kremling";
import { func, array } from "prop-types";
import { isEmpty } from "lodash";

export default class GovernmentCodeList extends React.Component {
  static propTypes = {
    updateGovCode: func,
    governmentCodes: array,
  };

  render() {
    const { activeGovernmentCode, governmentCodes } = this.props;

    return (
      <Scoped css={css}>
        {!isEmpty(this.props.governmentCodes) && (
          <div className="gov-code-list">
            {governmentCodes.map((item) => {
              return (
                <div
                  className={a("gov-code-list-item").m(
                    "active-gov-code-item",
                    activeGovernmentCode === item.code
                  )}
                  onClick={() => {
                    this.props.updateGovCode(item.code);
                  }}
                  key={item.code}
                >
                  {item.name}
                </div>
              );
            })}
          </div>
        )}
      </Scoped>
    );
  }
}

const css = k`
  .gov-code-list {
    height: 750px;
    width: 345px;
    overflow: scroll;
    border-top: 1px solid var(--cps-color-athens);
    border-bottom: 1px solid var(--cps-color-athens);
    border-radius: 4px 0 0 4px;
  }

  .gov-code-list-item {
    display: flex;
    align-items: center;
    font-weight: 600;
    height: 50px;
    width: 330px;
    border-top: 1px solid var(--cps-color-athens);
    border-left: 1px solid var(--cps-color-athens);
    padding-left: 16px;
  }

  .gov-code-list-item:first-child {
    border-top: none;
  }

  .gov-code-list-item:hover {
    background-color: var(--cps-color-chrome)
  }

  .active-gov-code-item {
    border-left: 2px solid var(--cps-color-primary);
  }
`;

import { get, capitalize, isEmpty, lowerCase } from "lodash";

export const nameilizer = (pref, form, suff) => {
  const nameFieldsExist =
    form.lastModifiedBy && form.lastModifiedBy !== "undefined";
  if (nameFieldsExist) {
    const first = capitalize(get(form, "lastModifiedBy.firstName"));
    const last = capitalize(get(form, "lastModifiedBy.lastName"));
    return isEmpty(first) && isEmpty(last)
      ? ""
      : `${pref} ${first} ${last} ${suff}`;
  }
};

export const dateifinizer = (form) => {
  let def = "--";
  if (!isEmpty(form.lastModified)) {
    const modifiedDate = get(form, "lastModified", "");
    const dateParts = modifiedDate.split(" ");
    let [date, time, period, ...stuffWeDontWant] = dateParts;
    return dateParts.length > 1 ? `${date} @ ${time}${lowerCase(period)}` : def;
  }
  return def;
};

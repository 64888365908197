import { without, find } from "lodash";

import * as types from "src/versions/versions.types.js";

const defaultState = {
  versionsList: [],
  selectedVersion: null,
  diffDialog: null,
};

function reduceVersions(state, action) {
  if (action.type === types.VERSIONS_LOADED) {
    return action.versions;
  }

  if (action.type === types.VERSION_DELETED) {
    return [...without(state, action.version)];
  }

  if (action.type === types.VERSION_CREATED) {
    return [...state, action.version];
  }

  if (action.type === types.VERSION_UPDATED) {
    return [
      ...without(state, find(state, { id: action.version.id })),
      action.version,
    ];
  }

  return state;
}

function reduceSelected(state, action) {
  if (action.type === types.VERSION_SELECTED) {
    if (action.version === state) {
      return null;
    } else {
      return action.version;
    }
  }

  return state;
}

function reduceDiffDialog(state, action) {
  if (action.type === "DISPLAYED_DIFF_DIALOG") {
    return { version: action.version, revision: action.revision };
  }

  if (action.type === "CLOSED_DIFF_DIALOG") {
    return null;
  }

  return state;
}

export default function (state = defaultState, action) {
  return {
    versionsList: reduceVersions(state.versionsList, action),
    selectedVersion: reduceSelected(state.selectedVersion, action),
    diffDialog: reduceDiffDialog(state.diffDialog, action),
  };
}

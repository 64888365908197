import { findIndex } from "lodash";

import * as types from "src/source-forms/forms/forms.types.js";
import sectionsReducer from "src/source-forms/sections/sections.reducer.js";
import subSectionsReducer from "src/source-forms/sub-sections/sub-sections.reducer.js";
import blocksReducer from "src/source-forms/questions/blocks.reducer.js";

const defaultState = {};
const defaultActiveForm = null;

function activeFormReducer(state = defaultActiveForm, action) {
  if (action.type === types.LOADED_FORM) {
    return { ...action.form };
  }

  if (action.type === "UNLOADED_FORM") {
    return defaultActiveForm;
  }

  if (action.type === "LOADED_SECTION" && state !== defaultActiveForm) {
    return { ...state, sections: mergeSection(state.sections, action.section) };
  }

  return state;
}

function mergeSection(sections, section) {
  const index = findIndex(sections, { id: section.id });

  if (index < 0) return sections;

  let newSections = [...sections];
  newSections[index] = section;

  return newSections;
}

export default function formsReducer(state = defaultState, action) {
  return {
    activeForm: activeFormReducer(state.activeForm, action),
    activeSection: sectionsReducer(state.activeSection, action),
    activeSubSection: subSectionsReducer(state.activeSubSection, action),
    activeBlock: blocksReducer(state.activeBlock, action),
  };
}

// Folder types
export const LOADED_QUESTION_FOLDERS = "LOADED_QUESTION_FOLDERS";
export const CHANGED_ACTIVE_FOLDER = "CHANGED_ACTIVE_FOLDER";

// Drag and Drop types
export const DRAG_QUESTION_MENU_ELEMENT = "DRAG_QUESTION_MENU_ELEMENT";

// Question types
export const CHANGED_ACTIVE_QUESTION = "CHANGED_ACTIVE_QUESTION";
export const CREATED_QUESTION = "CREATED_QUESTION";

export const LOADED_QUESTION_REFERENCES = "LOADED_QUESTION_REFERENCES";
export const CLEARED_QUESTION_REFERENCES = "CLEARED_QUESTION_REFERENCES";

export const LOADED_SEARCHED_QUESTIONS = "LOADED_SEARCHED_QUESTIONS";
export const INITIALIZED_QUESTION_BANK = "INITIALIZED_QUESTION_BANK";

import React from "react";

export default class DiffAccordion extends React.Component {
  constructor() {
    super();
    this.state = {
      expanded: false,
    };
  }

  render() {
    const { list, keyProp, name } = this.props;
    const { expanded } = this.state;

    if (!list || !list.length) return null;

    return (
      <div style={{ marginTop: "24px" }}>
        <div
          onClick={this.toggle.bind(this)}
          className="cps-subheader-sm cps-cursor-pointer"
        >
          Changes to {name} ({list.length}):
        </div>
        {expanded ? (
          <table className="cps-inset-table">
            <tbody>
              {list.map((el) => (
                <tr key={el[keyProp]}>
                  <td>{this.getIcons(el)}</td>
                  <td />
                  <td />
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <div style={{}} />
        )}
      </div>
    );
  }

  toggle() {
    this.setState({ expanded: !this.state.expanded });
  }

  getIcons(el) {
    const value = el.name || el.key;
    const link = this.props.getLink ? (
      <a href={this.props.getLink(el)}>{value}</a>
    ) : (
      value
    );

    if (el.isNew)
      return (
        <i title="Added" className="cps-icon cps-icon-add cps-success">
          <span style={{ top: -4 }}>{link}</span>
        </i>
      );

    if (el.isDeleted)
      return (
        <i title="Removed" className="cps-icon cps-icon-trash cps-warning">
          <span style={{ top: -4 }} className="cps-general">
            {value}
          </span>
        </i>
      );

    return (
      <i
        style={{
          position: "relative",
          left: 24,
          top: 2,
        }}
        className="cps-icon"
      >
        <span>{link}</span>
      </i>
    );
  }
}

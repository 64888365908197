import { groupBy, difference, findIndex, isEmpty, reduce } from "lodash";

const ROW_HEIGHT = 40;
const FULL_WIDTH = 712;
const HEADER_VERTICAL_HEIGHT = 48;

export function getEmptyCoordinates() {
  const half = FULL_WIDTH / 2;

  return [
    {
      x: 0,
      y: 0,
      width: half,
      height: HEADER_VERTICAL_HEIGHT,
      coordType: "text",
      text: "Header 1",
    },
    {
      x: 0,
      y: HEADER_VERTICAL_HEIGHT,
      width: half,
      height: ROW_HEIGHT,
      coordType: "text",
      text: "",
    },
    {
      x: half,
      y: 0,
      width: half,
      height: HEADER_VERTICAL_HEIGHT,
      coordType: "text",
      text: "Header 2",
    },
    {
      x: half,
      y: HEADER_VERTICAL_HEIGHT,
      width: half,
      height: ROW_HEIGHT,
      coordType: "text",
      text: "",
    },
  ];
}

export function getMaxHeight(coords = []) {
  return (coords || []).reduce(toLargestHeight, 0) + "px";

  function toLargestHeight(height, coord) {
    if (coord.y + coord.height > height) {
      return coord.y + coord.height;
    }

    return height;
  }
}

export function groupCoordsByColumn(coords) {
  return groupBy(coords, "x");
}

export function groupCoordsByRow(coords) {
  return groupBy(coords, "y");
}

export function addBars(coords) {
  return Object.keys(coords).reduce(
    (acc, curr) => ({
      ...acc,
      [`${curr}plus`]: {
        tool: true,
        y: parseInt(curr, 10),
        topTrig: parseInt(curr, 10) - coords[curr][0].height / 2,
        bottomTrig: parseInt(curr, 10) + coords[curr][0].height / 2,
      },
      [curr]: coords[curr],
    }),
    {}
  );
}

export function addColumnBars(coords) {
  return Object.keys(coords).reduce(
    (acc, curr) => ({
      ...acc,
      [`${curr}plus`]: {
        tool: true,
        x: parseInt(curr, 10),
        topTrig: parseInt(curr, 10) - coords[curr][0].width / 2,
        bottomTrig: parseInt(curr, 10) + coords[curr][0].width / 2,
      },
      [curr]: coords[curr],
    }),
    {
      ["subZero"]: {
        tool: true,
        x: "subZero",
      },
    }
  );
}

export function getCoordsInSameRow(allCoords = [], coord = {}) {
  return allCoords.filter((c) => {
    return c.y === coord.y;
  });
}

export function getCoordsInSameCol(allCoords = [], coord = {}) {
  return allCoords.filter((c) => {
    return c.x === coord.x;
  });
}

export function coordIsHeader(coord = {}) {
  return coord.y === 0;
}

export function coordIsInLastColumn(allCoords = [], coord = {}) {
  return !allCoords.reduce((isNotLast, c) => {
    if (c.x > coord.x) return true;
    return isNotLast;
  }, false);
}

export function addRow(coords, thisRow, rowVisibilities) {
  const cols = groupBy(coords, "x");
  let newRowVisibilities = [];

  const newCoords = coords.map((cell) => {
    if (cell.y > thisRow.y) {
      return {
        ...cell,
        y: cell.y + thisRow.height,
      };
    }
    return cell;
  });

  if (!isEmpty(rowVisibilities)) {
    newRowVisibilities = rowVisibilities.map((rule) => {
      if (rule.y > thisRow.y) {
        return {
          ...rule,
          y: rule.y + thisRow.height,
        };
      }
      return rule;
    });
  }

  return {
    coords: [
      ...newCoords,
      ...Object.keys(cols).map((col) => ({
        x: cols[col][0].x,
        width: cols[col][0].width,
        text: "",
        y: thisRow.y + thisRow.height,
        height: ROW_HEIGHT,
        coordType: "text",
      })),
    ],
    rowVisibilities: newRowVisibilities,
  };
}

export function addCol(coords, thisColumn) {
  const rows = groupBy(coords, "y");
  const cols = groupBy(coords, "x");

  if (thisColumn) {
    // 25 columns is the max for a fixed table
    if (Object.keys(cols).length > 24) {
      return coords;
    }

    const newCoords = coords.map((cell) =>
      cell.x === thisColumn.x
        ? {
            ...cell,
            width: cell.width / 2,
          }
        : cell
    );

    return [
      ...newCoords,
      ...Object.keys(rows).map((row) => ({
        x: thisColumn.x + thisColumn.width / 2,
        width: thisColumn.width / 2,
        y: rows[row][0].y,
        height: rows[row][0].height,
        text: "",
        coordType: "text",
      })),
    ];
  } else {
    const newCoords = coords.map((cell) =>
      cell.x === 0
        ? {
            ...cell,
            x: cell.width / 2,
            width: cell.width / 2,
          }
        : cell
    );
    return [
      ...Object.keys(rows).map((row) => ({
        x: 0,
        width: coords[0].width / 2,
        y: rows[row][0].y,
        height: rows[row][0].height,
        text: "",
        coordType: "text",
      })),
      ...newCoords,
    ];
  }
}

export function resizeColumns(coords, column, anchor, clientX) {
  const change = clientX - anchor;

  return coords.map((coord) => {
    if (coord.x === column) {
      return {
        ...coord,
        x: coord.x + change,
        width: coord.width - change,
      };
    } else if (coord.x + coord.width === column) {
      return {
        ...coord,
        width: coord.width + change,
      };
    } else {
      return coord;
    }
  });
}

export function removeCol(coord, coords) {
  coords = difference(coords, getCoordsInSameCol(coords, coord));

  return coords
    .map(toCoordsWithoutColumn.bind(null, coord))
    .map(toRebalancedColWidths.bind(null, coord));

  function toCoordsWithoutColumn(coord, c) {
    if (c.x > coord.x) {
      return {
        ...c,
        x: c.x - coord.width,
      };
    } else {
      return c;
    }
  }

  function toRebalancedColWidths(coord, c, i, coords) {
    if (coordIsInLastColumn(coords, c)) {
      return {
        ...c,
        width:
          c.width +
          FULL_WIDTH -
          sumAttribute(getCoordsInSameRow(coords, c), "width"),
      };
    } else {
      return c;
    }
  }
}

export function removeRow(coord, coords, rowVisibilities) {
  coords = difference(coords, getCoordsInSameRow(coords, coord));
  let newRowVisibilities =
    rowVisibilities &&
    rowVisibilities.filter((rule) => {
      return rule.y !== coord.y;
    });

  const newCoords = coords.map((c) => {
    if (c.y > coord.y) {
      return {
        ...c,
        y: c.y - coord.height,
      };
    }
    return c;
  });

  if (rowVisibilities && !isEmpty(newRowVisibilities)) {
    newRowVisibilities = newRowVisibilities.map((rule) => {
      if (rule.y > coord.y) {
        return {
          ...rule,
          y: rule.y - coord.height,
        };
      }
      return rule;
    });
  }

  return {
    coords: [...newCoords],
    rowVisibilities: newRowVisibilities,
  };
}

export function removeQuestion(coord, coords) {
  coords = [...coords];
  let index = findIndex(coords, coord);
  coords[index] = {
    ...coord,
    coordType: "text",
    question: null,
    text: "",
  };
  return coords;
}

function sumAttribute(els, attr) {
  return els.reduce((total, el) => {
    return total + el[attr];
  }, 0);
}

export function updateTablesWithSharedValue(tables, value) {
  return reduce(
    tables,
    (tableMap, table, name) => {
      return {
        ...tableMap,
        [name]: table.map(toCoordWithMergedValue.bind(null, value)),
      };
    },
    {}
  );

  function toCoordWithMergedValue(value, coord) {
    if (coord.coordType === "question" && coord.question.name === value.key) {
      return {
        ...coord,
        question: {
          ...coord.question,
          meta: {
            ...coord.question.meta,
            value: value.value,
          },
        },
      };
    } else {
      return coord;
    }
  }
}

export function applyUpdatedQuestion(clone, action) {
  return Object.keys(clone.tables).reduce((acc, curr) => {
    const index = findIndex(
      clone.tables[curr],
      (t) => t.question && t.question.name === action.question.name
    );
    return {
      ...acc,
      [curr]:
        index > -1
          ? clone.tables[curr].map((table, i) =>
              i === index ? { ...table, question: action.question } : table
            )
          : clone.tables[curr],
    };
  }, {});
}

import { fetchAsObservable } from "fetcher!sofe";
import { pluck } from "rxjs/operators";

export const getVersions = () => {
  return fetchAsObservable(
    `/versions/?revisionLimit=-1&excludePdfVersions=true`
  ).pipe(pluck("versions"));
};

export const getVersion = (versionId) => {
  return fetchAsObservable(
    `/versions/${versionId}?revisionLimit=-1&excludePdfVersions=true`
  ).pipe(pluck("versions"));
};

export const createVersion = (name, description, year, product, copiedFrom) => {
  const payload = {
    name: name,
    description: description,
  };

  if (copiedFrom) {
    payload.copiedFrom = copiedFrom;
    payload.year = year;
  }

  if (product) {
    payload.product = product;
  }

  if (product === "taxPrep") {
    payload.year = year;
  }

  return fetchAsObservable(`/versions/`, {
    method: "POST",
    body: JSON.stringify({
      versions: {
        ...payload,
      },
    }),
  }).pipe(pluck("versions"));
};

export const createRevision = (revision, name, description) => {
  return fetchAsObservable(`/revisions/`, {
    method: "POST",
    body: JSON.stringify({
      revisions: {
        name,
        description,
        copiedFrom: `${revision.version}-${revision.id}`,
      },
    }),
  }).pipe(pluck("revisions"));
};

export const updateARevision = (
  revisionVersion,
  revisionId,
  name,
  description
) => {
  return fetchAsObservable(`/revisions/${revisionVersion}-${revisionId}`, {
    method: "PATCH",
    body: {
      revisions: {
        name,
        description,
      },
    },
  }).pipe(pluck("revisions"));
};

export const deleteRevision = (revision) => {
  return fetchAsObservable(`/revisions/${revision.version}-${revision.id}`, {
    method: "DELETE",
  });
};

export const setRevisionActive = (revision) => {
  return fetchAsObservable(`/revisions/${revision.version}-${revision.id}`, {
    method: "PATCH",
    body: JSON.stringify({
      revisions: {
        status: "live",
      },
    }),
  });
};

export const diffWithOldRevision = (revision, oldRevision) => {
  return fetchAsObservable(
    `/revisions/${revision.version}-${revision.id}/diffs?oldVersion=${oldRevision.version}-${oldRevision.id}`,
    {}
  );
};

export const getGovernmentCodes = (versionId) => {
  return fetchAsObservable(`/versions/${versionId}/government-codes/`).pipe(
    pluck("government-codes")
  );
};

export const getGovernmentCodeBlacklist = (governmentCode, versionId) => {
  return fetchAsObservable(
    `/versions/${versionId}/government-codes/${governmentCode}/blacklist`
  ).pipe(pluck("blacklist"));
};

export const updateEfileSubTypeBlacklist = (
  governmentCode,
  versionId,
  submissionType,
  blacklisted
) => {
  return fetchAsObservable(
    `/versions/${versionId}/government-codes/${governmentCode}/submission-types/${submissionType}/blacklist`,
    {
      method: "PATCH",
      body: JSON.stringify({
        blacklist: blacklisted,
      }),
    }
  ).pipe(pluck("blacklist"));
};

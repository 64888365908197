const DEFAULT_STATE = null;

export default function sectionsReducer(state = DEFAULT_STATE, action) {
  if (action.type === "UNLOADED_SECTION") {
    return DEFAULT_STATE;
  }

  if (action.type === "LOADED_SECTION") {
    return action.section;
  }

  return state;
}

export const STARTED_EDITING_FIXED_TABLE = "STARTED_EDITING_FIXED_TABLE";
export const MODIFIED_EDITING_STATE = "MODIFIED_EDITING_STATE";
export const CLOSED_FIXED_TABLE = "CLOSED_FIXED_TABLE";

export const HOVERED_FIXED_TABLE = "HOVERED_FIXED_TABLE";
export const STOPPED_HOVERING_FIXED_TABLE = "STOPPED_HOVERING_FIXED_TABLE";

export const FIXED_TABLE_STARTED_DRAGGING_COLUMN =
  "FIXED_TABLE_STARTED_DRAGGING_COLUMN";
export const FIXED_TABLE_DRAGGED_COLUMN = "FIXED_TABLE_DRAGGED_COLUMN";
export const FIXED_TABLE_STOPPED_DRAGGING_COLUMN =
  "FIXED_TABLE_STOPPED_DRAGGING_COLUMN";

export const FIXED_TABLE_UPDATED_SHARED_VALUE =
  "FIXED_TABLE_UPDATED_SHARED_VALUE";

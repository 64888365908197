import { omit, without, sortBy, chain, find } from "lodash";

export {
  getDraftCount,
  getModifiedDate,
  isVersionPublished,
  sortVersion,
  getPublishedVersions,
  getUnpublishedVersions,
};

function sortVersion(version) {
  return getModifiedDate(version);
}

function getDraftCount(version) {
  return (version.revisions || []).filter((revision) => !revision.isPublished)
    .length;
}

function isVersionPublished(version) {
  return version.revisions.reduce((foundPublished, revision) => {
    if (foundPublished || revision.isPublished) return true;
    return false;
  }, false);
}

function getPublishedVersions(versions) {
  return chain(versions)
    .filter((version) => isVersionPublished(version))
    .sortBy(sortVersion)
    .reverse()
    .value();
}

function getUnpublishedVersions(versions) {
  return chain(versions)
    .without(...getPublishedVersions(versions))
    .sortBy(sortVersion)
    .reverse()
    .value();
}

function getModifiedDate(version) {
  return version.revisions.reduce((latestDate, revision) => {
    return latestDate < revision.creationDate
      ? revision.creationDate
      : latestDate;
  }, 0);
}

export const removeYearIfTaxRes = (version) =>
  version.product && !!~version.product.indexOf("taxRes")
    ? omit(version, ["year"])
    : version;

export const getDraft = (versions, smeProduct) => {
  const product = smeProduct ? smeProduct : "taxPrep";

  const revision = versions.reduce((latest, version) => {
    if (version.product !== product) {
      return latest;
    }

    return version.revisions.reduce(
      (acc, curr) =>
        curr.status !== "workInProgress"
          ? acc
          : !acc || curr.creationDate > acc.creationDate
          ? curr
          : acc,
      latest
    );
  }, null);

  return `${revision.version}-${revision.id}`;
};

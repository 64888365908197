// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".global-style__cps-card--xTmcw .global-style__version--8piPk > * {\n  background-color: #fafafa;\n}\n\n.cps-nav-content.\\+tall-top {\n  padding-top: 124px;\n}\n", "",{"version":3,"sources":["webpack://./src/global.style.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;AAC3B;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":[".cps-card :local(.version) > * {\n  background-color: #fafafa;\n}\n\n:global(.cps-nav-content.\\+tall-top) {\n  padding-top: 124px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cps-card": "global-style__cps-card--xTmcw",
	"version": "global-style__version--8piPk"
};
export default ___CSS_LOADER_EXPORT___;

import { findIndex, cloneDeep } from "lodash";
import * as types from "src/shared-values/shared-values.types.js";

const defaultState = {
  values: [],
  oldValues: null,
  sharedValueReferences: {},
  updatingSharedValue: null,
};

export default function sharedValuesReducer(state = defaultState, action) {
  if (action.type === types.LOADED_SHARED_VALUES) {
    return {
      ...state,
      values: action.values,
    };
  }

  if (action.type === types.UPDATED_SHARED_VALUE) {
    const newValues = cloneDeep(state.values);
    const index = findIndex(state.values, action.oldValue);
    newValues[index] = action.newValue;

    return {
      ...state,
      updatingSharedValue: null,
      values: newValues,
    };
  }

  if (action.type === types.UPDATING_SHARED_VALUE) {
    return {
      ...state,
      updatingSharedValue: action.id,
    };
  }

  if (action.type === types.LOADED_SHARED_VALUE_REFERENCES) {
    return {
      ...state,
      sharedValueReferences: {
        references: action.references,
        name: action.name,
      },
    };
  }

  if (action.type === types.CLEARED_SHARED_VALUE_REFERENCES) {
    return {
      ...state,
      sharedValueReferences: {},
    };
  }

  if (action.type === "LOADED_OLD_SHARED_VALUES") {
    return {
      ...state,
      oldValues: action.values,
    };
  }

  return state;
}

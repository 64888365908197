import GroupsReducer from "src/source-forms/groups/groups.reducer.js";
import FormsReducer from "src/source-forms/forms/forms.reducer.js";
import SummaryTableReducer from "src/source-forms/summary-table/summary-table.reducer.js";
import FixedTableReducer from "src/source-forms/fixed-table/fixed-table.reducer.js";

const defaultState = {};

export default function (state = defaultState, action) {
  return {
    groups: GroupsReducer(state.groups, action),
    forms: FormsReducer(state.forms, action),
    summaryTable: SummaryTableReducer(state.summaryTable, action),
    fixedTable: FixedTableReducer(state.fixedTable, action),
  };
}

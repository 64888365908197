import { values, indexOf, orderBy, isEmpty } from "lodash";

export const determineCaret = (column, currentSort) => {
  if (column.name === currentSort.columnName) {
    return currentSort.direction === "asc"
      ? "caret-small-up"
      : "caret-small-down";
  }
};

export const determineNextCaretState = (column, currentSort) => {
  return currentSort.direction === "asc" ? "desc" : "asc";
};

export const formIsMatch = (form, filter) => {
  return values(form).find(
    (item) =>
      typeof item === "string" &&
      item.toLocaleLowerCase().indexOf(filter.toLocaleLowerCase()) !== -1
  );
};

export const columnSort = (list, column, direction) => {
  const vals = list.map((form) => {
    return { ...form, secretVal: compValue(form, column) };
  });

  return orderBy(vals, "secretVal", direction);
};

export function compValue(val, columnName) {
  const strs = ["name", "type"];
  if (indexOf(strs, columnName) > -1) {
    return val[columnName].toLowerCase();
  } else if (columnName === "lastModified") {
    return convDate(val[columnName]);
  } else {
    return !isEmpty(val[columnName]) ? parseFloat(val[columnName], 10) : 0;
  }
}

function convDate(dateTime) {
  const newDate = new Date(dateTime);
  return !isEmpty(dateTime) ? newDate.getTime() : 0;
}

export function shouldLoadMore() {
  const scrollTop = window.pageYOffset || document.body.scrollTop;
  const height = window.innerHeight || document.documentElement.offsetHeight;
  const docHeight = document.body.clientHeight;
  return scrollTop + height >= docHeight - 100;
}
